import { http } from '../../http';
import * as actionTypes from "../actionTypes";
import { 
  GetWellbeingAssessmentDetails,
  GetHealthAndWellbeingAssessmentForFourCard,
  GetTopWellnessWins,
  GetTopAddressableHelthRisk,
  GetWellbeingCategories,
  GetWBAQuestionByCategoryId,
  GetTopHealthImprovementOpportunities
} from "../../constants/apiConstants";
// import { fetchReportTabAction } from '../index';

const fetchWellbeingAssessmentDetails = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetHealthAndWellbeingAssessmentForFourCard}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (!res.message) {
        dispatch({ type: actionTypes.HEALTH_WELLBEING_CARD, healthWellbeingCardData: res });
      }
    } catch (error) {
      // toast.error(error.message);
    }
  }
};

const fetchHealthAndWellbeingAssessmentForFourCard = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetWellbeingAssessmentDetails}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (!res.message) {
        dispatch({ type: actionTypes.ASSESMENT_DETAILS, assesmentDetails: res });
      }
    } catch (error) {
      // toast.error(error.message);
    }
  }
};

const fetchTopWellnessWins = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopWellnessWins}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (!res.message) {
        dispatch({ type: actionTypes.TOP_WELLNESS_WINS, wellnessWins: res });
      }
    } catch (error) {
      // toast.error(error.message);
    }
  }
};

const fetchTopAddressableHelthRisk = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopAddressableHelthRisk}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (!res.message) {
        dispatch({ type: actionTypes.TOP_ADDRESSABLE_HEALTH_RISK, addressableHealthRisk: res });
      }
    } 
    catch (error) {
      // toast.error(error.message);
    }
  }
};

const fetchOverviewTabApi = (id, year) => (dispatch) => {
  // dispatch(fetchReportTabAction(id, year));
  dispatch(fetchWellbeingAssessmentDetails(id, year));
  dispatch(fetchTopAddressableHelthRisk(id, year));
  dispatch(fetchTopWellnessWins(id, year));
  dispatch(fetchHealthAndWellbeingAssessmentForFourCard(id, year));
  dispatch(fetchhealthImprovementOpportunities(id, year))
};

const fetWellbeingCategories = () => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(GetWellbeingCategories, { headers: { AUTHTOKEN: AuthToken } });
      if (!res.message) {
        dispatch({ type: actionTypes.GET_WELLBEING_CATEGORIES, wellbeingCategoriesData: res });
      }
    } 
    catch (error) {
      // toast.error(error.message);
    }
  }
};

const fetchWBAQuestionByCategoryId = (companyId,categoryId,year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetWBAQuestionByCategoryId}/${companyId}/${categoryId}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (!res.message) {
        dispatch({ type: actionTypes.GET_WBA_QUESTION_BY_CATEGORYID, WbaQuestionData: res });
      }
    } 
    catch (error) {
      // toast.error(error.message);
    }
  }
};

const fetchhealthImprovementOpportunities = (id, year) => {
  const AuthToken = localStorage.getItem('AUTH_TOKEN');
  return async (dispatch) => {
    try {
      const res = await http.get(`${GetTopHealthImprovementOpportunities}/${id}/${year}`, { headers: { AUTHTOKEN: AuthToken } });
      if (!res.message) {
        dispatch({ type: actionTypes.TOP_HEALTH_IMPROVEMENT, healthImprovementOpportunities: res });
      }
    } catch (error) {
      // toast.error(error.message);
    }
  }
};

export { fetchWellbeingAssessmentDetails, fetchTopAddressableHelthRisk, fetchTopWellnessWins, fetchHealthAndWellbeingAssessmentForFourCard, fetchOverviewTabApi, fetWellbeingCategories, fetchWBAQuestionByCategoryId, fetchhealthImprovementOpportunities };