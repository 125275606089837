/* eslint-disable no-console,no-undef, camelcase, react/no-unknown-property */
/* eslint-disable react/no-unknown-property */
import * as Sentry from "@sentry/react";
// import { RewriteFrames } from "@sentry/integrations";

import React, { Suspense } from "react";
// import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import "babel-polyfill";
import "raf/polyfill";

import { NotFound, Waiting } from "./components";
import AuthenticatedRoutes from "./authenticatedRoutes";

import store from "./redux/store";

// import 'bootstrap/dist/css/bootstrap.min.css';
import './css/bootstrap.min.css';
import "./css/sty.styl";
import "./css/app.css";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
// import 'react-player/coverage/base.css';
// import "react-add-to-calendar/dist/react-add-to-calendar.css";
import { checkPermission } from "./utils/methods";
import { COMPANY_NAME } from "./utils/config";
document.title = COMPANY_NAME;
import { WhyAmigo, Pricing, HomePage, LoginV2, ComingSoon, ContactUs, PrivacyPolicy, TermsOfCondition, TermsOfService, Platform, About,Intranet, Wellness, Engagement, WellnessEducation, WellnessRecipes, WellnessFitness, WellnessChallenges, WellnessBehaviour, WellnessAdmin, PricingPageHome, RequestDemoPage, RegistrationPage, EventsNew, CommunityRecognition, RewardsWebContainer} from './containers';
// import TagManager from "react-gtm-module";
// const tagManagerArgs = {
//   gtmId: "GTM-KT48S4G",
// };
// TagManager.initialize(tagManagerArgs);

import "../src/Lang/i18n";

process.env.NODE_ENV === "production" && Sentry.init({
  dsn: "https://54fcb1a0e7498186d59b3821e3ae8a8e@o951569.ingest.sentry.io/4506029750484992",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/woliba\.io\//],
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment:process.env.ENVIRONMENT
});


class App extends React.Component {
  UNSAFE_componentWillMount() {
    checkPermission();
  }

  componentDidMount(){
    window.console.log(process.env.ENVIRONMENT)
  }

  redirectPolicy = () => <PrivacyPolicy showFooterAndHeader={true} />;

  redirectMobilePolicy = () => <PrivacyPolicy showFooterAndHeader={false} />;

  redirectTermsPage = () => <TermsOfCondition showFooterAndHeader={true} />;

  redirectMobileTermsPage = () => (
    <TermsOfCondition showFooterAndHeader={false} />
  );

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<Waiting/>}>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/index" component={HomePage} />
              <Route path="/user" component={LoginV2} />
              <Route path="/about" component={About} />
              <Route path="/privacy-policy" render={this.redirectPolicy} />
              <Route
                path="/mobile-privacy-policy"
                render={this.redirectMobilePolicy}
              />
              <Route path="/terms-use" render={this.redirectTermsPage} />
              <Route
                path="/mobile-terms-use"
                render={this.redirectMobileTermsPage}
              />
              <Route path="/terms-service" component={TermsOfService} />
              <Route path="/blogs" component={ComingSoon} />
              <Route path="/request-demo" component={ContactUs} />
              <Route path="/thankyou" component={ContactUs} />
              <Route path="/pricing-page" component={Pricing} />
              <Route path="/why-woliba" component={WhyAmigo} />
              <Route path="/community-page" component={Platform} />
              <Route path="/employee-engagement" component={Intranet} />
              <Route path="/admin" component={Engagement} />
              <Route path="/corporate-wellness" component={Wellness} />
              <Route path="/wellness-education" component={WellnessEducation} />
              <Route path="/nutrition-recipes" component={WellnessRecipes} />
              <Route path="/fitness-videos" component={WellnessFitness} />
              <Route
                path="/activity-challenges"
                component={WellnessChallenges}
              />
              <Route
                path="/wellness-challenges"
                component={WellnessBehaviour}
              />
              <Route path="/wellness-admin" component={WellnessAdmin} />
              <Route path="/pricing" component={PricingPageHome} />
              <Route path="/contact" component={RequestDemoPage} />
              <Route path="/register" component={RegistrationPage} />
              <Route path="/wellness-events" component={EventsNew} />
              <Route
                path="/employee-recognition"
                component={CommunityRecognition}
              />
              <Route path="/employee-rewards" component={RewardsWebContainer} />
              <Redirect from="/recognise" to="/employee-recognition" />
              <Redirect from="/reward-page" to="/employee-rewards" />
              <Redirect from="/why-amigo/how-it-works" to="/why-woliba" />
              <Redirect from="/wellness" to="/corporate-wellness" />
              <Redirect from="/engagement" to="/employee-engagement" />
              <Redirect from="/web-events" to="/wellness-events" />
              <Redirect from="/wellness-recipes" to="/nutrition-recipes" />
              <Redirect from="/wellness-fitness" to="/fitness-videos" />

              {/* <Route path="/corporate-wellbeing-software" component={WellbeingPageV2} /> */}
              <AuthenticatedRoutes />
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </Provider>
    );
  }
}

// process.env.NODE_ENV === "production" ? ReactDOM.render(<ErrorBoundary><App /></ErrorBoundary>, document.getElementById("app")) : ReactDOM.render(<App />, document.getElementById("app"));
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

